import { configure_client } from "./services/client_config";

console.log("SETTING UP ENVIRONMENT!");

export enum Environment {
    DEVELOPMENT = "dev",
    PRODUCTION = "prod",
    STAGING = "staging"
}


export type EnvironmentInterface = {
    ENVIRONMENT: Environment;
    BASE_URL: string;
    API_URL: string;
}

export const environment:EnvironmentInterface = {
    ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT as Environment,
    BASE_URL: process.env.REACT_APP_BASE_URL as string,
    API_URL: process.env.REACT_APP_API_URL as string,
}

export function setup_environment() {
    configure_client(environment);
}