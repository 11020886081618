import {Breadcrumb, Button, Radio, Space} from "antd";
import Editor from "react-simple-code-editor";
import React, {useState} from "react";
import {
    AISubmission,
    EvalStatusEnum,
    QueryResult,
    useServiceQuery,
    useTrainingMutation,
    useTrainingGetQuery
} from "../services";
// @ts-ignore
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';
import BreadcrumbItem from "antd/lib/breadcrumb/BreadcrumbItem";
import {useNavigate, useParams} from "react-router-dom";
import {JSONViewer} from "../components/JSONViewer";


export const AITrainingView = (props: {}) => {
    const { serviceId, submissionId } = useParams();
    const navigate = useNavigate();
    if(!serviceId || !submissionId){
        navigate("/");
    }
    // @ts-ignore
    const service_result:QueryResult<AIService> = useServiceQuery(serviceId);
    // @ts-ignore
    const training_result:QueryResult<AITraining> = useTrainingGetQuery(serviceId, submissionId);
    // @ts-ignore
    const training_mutation = useTrainingMutation(serviceId, submissionId);
    const [status, setStatus] = useState<string|null>(null);
    const [correction, setCorrection] = useState<string|null>(null);

    if(training_result.isPending || service_result.isPending ){
        return <div>Loading...</div>;
    }
    if(training_result.isError){
        return <div>Error: {training_result.error.message}</div>;
    }
    if(training_result.isSuccess && status === null){
        setCorrection(JSON.stringify(training_result.data.correction, null, 2));
        setStatus(training_result.data.evaluation_status);
    }

    function onSave() {
        if(!training_result.data) return;
        const record:AISubmission = training_result.data;
        if(status) record.evaluation_status = status as EvalStatusEnum;
        if(correction) record.correction = JSON.parse(correction);
        training_mutation.mutate(record);
    }

    function onBack(){
        navigate(`/services/${serviceId}`);
    }

    return <Space direction="vertical">
        <Breadcrumb>
            <BreadcrumbItem onClick={onBack}>Service: {service_result.data?.name}</BreadcrumbItem>
            <BreadcrumbItem>Training: {training_result.data.id}</BreadcrumbItem>
        </Breadcrumb>
        <Space direction="horizontal">
            <span>Status: </span>
            <Radio.Group value={status} onChange={(e) => setStatus(e.target.value)}>
                <Radio.Button value="pending">Pending</Radio.Button>
                <Radio.Button value="correct">Correct</Radio.Button>
                <Radio.Button value="incorrect">Incorrect</Radio.Button>
                <Radio.Button value="discard">Discard</Radio.Button>
            </Radio.Group>
        </Space>

        <h2>Input: </h2>
        <JSONViewer data={training_result.data.input} />

        <h2>Output: </h2>
        <JSONViewer data={training_result.data.output} />
        { status === "incorrect" &&
            <div>
                <h2>Corrected Output: </h2>
                <Editor
                      value={correction ? correction : ""}
                      onValueChange={(value) => setCorrection(value)}
                      highlight={code => highlight(code, languages.javascript)}
                      padding={10}
                  style={{
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 12,
                      minHeight: "200px",
                      backgroundColor: "#fff",
                  }}
                        />
                <Button onClick={onSave}>Save Correction</Button>
            </div>
        }
    </Space>;
}