import {AIService, ServiceDefinition, ServiceInput} from "../../services";
import {Button, Form, Input} from "antd";
import React from "react";


type ServiceRunFormProps = {
    service: AIService,
    onFinish: (values: any) => void,
    onFinishFailed: (errorInfo: any) => void
    onReset: () => void
}

export const ServiceRunForm = (props: ServiceRunFormProps) => {
    const form = Form.useForm()[0];
    const service_definition: ServiceDefinition = props.service.service_definition as ServiceDefinition;
    const inputs = service_definition.inputs;
    const [runnable, setRunnable] = React.useState(false);

    // Watch all values
    const values = Form.useWatch([], form);

    React.useEffect(() => {
        form.validateFields({validateOnly: true}).then(
            () => {
                setRunnable(true);
            },
            () => {
                setRunnable(false);
            },
        );
    }, [form, values]);

    function onReset() {
        form.resetFields();
        if (props.onReset) {
            props.onReset();
        }
    }

    return <>
        <Form form={form}
              initialValues={{}}
              onFinish={props.onFinish}
              onFinishFailed={props.onFinishFailed}>

            {inputs.map((input: ServiceInput) => {
                return <Form.Item key={input.name}
                                  label={input.name}
                                  name={input.name}
                                  rules={[{required: input.required, message: `${input.name} is required.`}]}>
                    <Input type={input.type}/>
                </Form.Item>
            })}

            <Form.Item wrapperCol={{offset: 0, span: 16}}>
                <Button type="primary" htmlType="submit" disabled={!runnable}>
                    Run
                </Button>
            </Form.Item>
            <Button type="default" onClick={onReset}>Clear</Button>
        </Form>
    </>
}