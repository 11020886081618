import React from "react";
import {Button, Form, FormInstance} from "antd";
import {
    ServiceOutputFormControl,
    serviceOutputValidator
} from "./service_output_form_control";
import {
    MinusCircleOutlined,
    PlusOutlined
} from "@ant-design/icons";


type ServiceOutputDictFormListProps = {
    name: any[],
    path: any[],
    form: FormInstance<any>;
}

export const ServiceOutputDictFormList: React.FC<ServiceOutputDictFormListProps> = (
    props: ServiceOutputDictFormListProps
) => {
    console.log("ServiceOutputDictFormList", props);
    return (
        <Form.List name={props.name}>
            {(fields, {add, remove}, {errors}) => (
                <>
                    {fields.map((field, index) => (
                        <div style={{
                            border: "1px dashed #ccc",
                            padding: "8px",
                            margin: "8px",
                            width: "100%"
                        }} key={field.key}>
                            <Form.Item
                                required={false}
                                rules={[{validator: serviceOutputValidator}]}
                                {...field}>
                                <ServiceOutputFormControl form={props.form}
                                                          name={[field.name]}
                                                          path={[...props.path, index]}/>
                            </Form.Item>
                            {fields.length > 1 ? (
                                <Button icon={<MinusCircleOutlined/>} danger={true}
                                        onClick={() => remove(field.name)}
                                >Delete</Button>
                            ) : null}
                        </div>
                    ))}

                    <Form.ErrorList errors={errors}/>

                    <Form.Item>
                        <Button
                            type="default"
                            onClick={() => add({
                                "name": "",
                                "type": "string",
                                "required": true
                            })}
                            style={{width: '100%'}}
                            icon={<PlusOutlined/>}
                        >
                            Add Item
                        </Button>
                    </Form.Item>
                </>
            )}
        </Form.List>
    )
}