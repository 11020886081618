import {QueryResult} from "./types";
import {useMutation, useQuery} from "@tanstack/react-query";
import {AccountCreationResult, AccountMeResult, DefaultService, IncomingUser, Account} from "./generated_client";
import {useRequireToken} from "./auth";


export function useAccountQuery():QueryResult<AccountMeResult> {
    useRequireToken();

    return useQuery({
        queryKey: ["account"],
        queryFn: async () => {
            const response: AccountMeResult = await DefaultService.getMeAccountsMeGet()
            return response;
        }
    });
}

export function useRegisterAccountMutation() {
    return useMutation({
        mutationFn: async (variables:IncomingUser):Promise<AccountCreationResult> => {
            const response = await DefaultService.createAccountAndUserAccountsPost(variables)
            return response;
        },
    })
}

export function useUpdateAccountMutation(accountId:number){
    useRequireToken();
    return useMutation({
        mutationFn: async (acct:Account): Promise<AccountMeResult> => {
            const response = await DefaultService.updateAccountAccountsAccountIdPost(accountId, acct);
            return response;
        },
    });
}
