import React, {FunctionComponent} from "react";
import {Button, Form, Input, Select, Table, Collapse} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import Title from "antd/lib/typography/Title";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import {updateObject} from "../utils/form";
import {OpenAIProviderSettingsForm} from "../components/ProviderSettings";
import {colNumberSort} from "../utils/sort";
import {
    AIProvider,
    AIModel,
    useModelListQuery,
    useModelMutation,
    useModelQuery,
    useProviderListQuery
} from "../services";

type FormType = {
    label?: string;
    provider_id?: number;
    provider_settings?: string;
};

type SelectOption = {
    value: number | undefined;
    label: string;
}

type SelectOptionGroup = {
    label: string;
    options?: SelectOption[];
}

function getOptions(providers: AIProvider[]) {
    const result:SelectOptionGroup[] = [];
    for( let provider of providers ) {
        let added = false;
        for( let group of result ){
            if(group.label === provider.provider) {
                if(!group.options){
                    group.options = [];
                }
                group.options.push({
                    value: provider.id,
                    label: provider.label,
                });
                added = true;
                break;
            }
        }
        if(!added){
            result.push({
                label: provider.provider?.toString() || "",
                options: [{
                    value: provider.id,
                    label: provider.label,
                }],
            });
        }
    }
    return result;
}

type ModelFormProps = {
    providers: AIProvider[],
    initialValues: FormType,
    onFinish: ((values: any) => void) | undefined,
    onFinishFailed: ((errorInfo: ValidateErrorEntity<any>) => void) | undefined
}

const ModelForm:React.FC<ModelFormProps> = ({
    providers,
    initialValues,
    onFinish,
    onFinishFailed
}) => {
    return <>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item<FormType>
              label="Label for Model"
              name="label"
              rules={[{ required: true, message: 'Please provide a model label!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item<FormType>
              label="Provider"
              name="provider_id"
              rules={[{ required: true, message: 'Please select a configured provider!' }]}>
                <Select options={getOptions(providers)} />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
    </>
}

export const NewModelView:FunctionComponent<{}> = (props: {}) => {
    const provider_list_query = useProviderListQuery();
    const model_mutation = useModelMutation(null);
    const navigate = useNavigate();

    if(model_mutation.isPending || provider_list_query.isPending){
        return <div>Loading...</div>
    }

    if(provider_list_query.isError){
        return <div>Error: {provider_list_query.error.toString()}</div>
    }

    function onFinish(values: any) {
        values["provider_settings"] = {};
        model_mutation.mutate(values, {
            onSuccess: (data) => {
                const model:AIModel = data;
                console.log("onSuccess: ", model);
                navigate(`/models/${model.id}`);
            }
        });
    }

    function onFinishFailed(errorInfo: any) {
        console.log(errorInfo);
    }

    return (<>
        <Button onClick={() => navigate("/models")}>Back</Button>
        <Title level={2}>Setup an AI Model</Title>
        <ModelForm providers={provider_list_query.data}
            initialValues={{}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed} />
    </>);
}


export const ModelView:FunctionComponent<{}> = (props: {}) => {
    const {modelId} = useParams();
    if(!modelId){
        throw new Error("No provider ID provided");
    }
    const model_query = useModelQuery(parseInt(modelId));
    const model_mutation = useModelMutation(parseInt(modelId));
    const provider_list_query = useProviderListQuery();
    const navigate = useNavigate();

    if(model_mutation.isPending || model_query.isPending || provider_list_query.isPending){
        return <div>Loading...</div>
    }

    if(model_query.isError){
        return <div>Error: {model_query.error.toString()}</div>
    }

    if(provider_list_query.isError){
        return <div>Error: {provider_list_query.error.toString()}</div>
    }

    function onFinish(values: any) {
        if(!model_query.data) return;
        const model = model_query.data;
        const result = updateObject(model, values);
        model_mutation.mutate(result as AIModel);
    }

    function onSettingsFinish(values: any) {
        if(!model_query.data) return;
        const model = model_query.data;
        const result = updateObject(model, {provider_settings: values});
        model_mutation.mutate(result as AIModel);
    }

    function onFinishFailed(errorInfo: any) {
        console.log(errorInfo);
    }
    // TODO: Add support for more than just OpenAI
    return (<>
        <Button onClick={() => navigate("/models")}>Back</Button>
        <Title level={2}>Update AI Model</Title>
        <ModelForm providers={provider_list_query.data}
            initialValues={{
                label: model_query.data.label,
                provider_id: model_query.data.provider_id,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed} />
        <Collapse
          collapsible="header"
          defaultActiveKey={['1']}
          items={[
            {
              key: '1',
              label: 'Advanced Settings',
              children: <OpenAIProviderSettingsForm
                  initialValues={model_query.data.provider_settings||{}}
                  onFinish={onSettingsFinish}
                  onFinishFailed={onFinishFailed} />
            },
          ]}
        />
    </>);
}

export const ModelListView:FunctionComponent<{}> = (props: {}) => {
    const model_list_query = useModelListQuery();
    const navigate = useNavigate();

    if(model_list_query.isPending){
        return <div>Loading...</div>
    }

    if(model_list_query.isError){
        return <div>Error: {model_list_query.error.toString()}</div>
    }

    const models:Array<AIModel> = model_list_query.data;
    return <>
        <h1>AI Models</h1>
        <ModelTable models={models} navigate={navigate} />
        <br/>
        <Button type="primary" onClick={()=>navigate("/models/new")}>Add New Model</Button>
    </>
}


type ModelTableProps = {
    models: Array<AIModel>,
    navigate: (value:string)=>void
}

export const ModelTable:FunctionComponent<ModelTableProps> = (props: ModelTableProps) => {
    return (<Table dataSource={props.models} rowKey="id">
        <Table.Column title="ID"
                      dataIndex="id"
                      key="id"
                      sorter={colNumberSort("id")}
                      sortDirections={['descend', 'ascend']}
                      defaultSortOrder="descend"
        />
        <Table.Column title="Label" dataIndex="label" key="label" />
        <Table.Column title="Details" key="select" render={(value, record:AIProvider, index) => {
            return <Button onClick={() => {
                props.navigate(`/models/${record.id}`)
            }}>More Info</Button>
        }} />
    </Table>);
}
