import React from 'react';
import './App.css';
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';
import Router from './router';

export const queryClient = new QueryClient({
    defaultOptions:{
        queries: {
            gcTime: 1000 * 60 * 60 * 24, // 1 day
        }
    }
});

const App: React.FC = () => {
    return (
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <Router />
            </QueryClientProvider>
        </React.StrictMode>
    );
};

export default App;