import {useMutation, useQueryClient} from "@tanstack/react-query";
import {DefaultService, LoginUser, Token} from "./generated_client";
import {useNavigate} from "react-router-dom";
import {SuccessCallbackFn} from "./types";


export function useLoginMutation(onSuccess?:SuccessCallbackFn) {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (user:LoginUser):Promise<Token> => {

            const result:Token = await DefaultService.loginLoginPost(user)
            // const response:AxiosResponse<Token> = await axios.post(`${environment.BASE_URL}/login`, user);
            return result;
        },
        onSuccess: (data: Token, variables, context) => {
            queryClient.setQueryData(["userToken"], data)
            if(onSuccess) onSuccess();
        },
        onError: async (error, variables, context) => {
            await queryClient.invalidateQueries({queryKey: ["userToken"]})
        }
    })
}

export function useLogoutMutation() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (variables) => {
            await queryClient.invalidateQueries({queryKey: ["userToken"]})
        },
    });
}

export function useRequireToken():void {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const token: Token|undefined = queryClient.getQueryData(["userToken"]);
    if (!token) {
        navigate("/auth/sign-in");
    }
}