import React, {FunctionComponent} from "react";
import {Button, Space} from "antd";
import {useNavigate} from "react-router-dom";
import {
    QueryResult,
    useAccountQuery,
    AIModel,
    AIProvider,
    AIService,
    AccountMeResult
} from "../services";
import {ModelTable} from "./ai_models";
import {ProviderTable} from "./provider";
import {ServiceTable} from "./ai_service";

export const HomeView:FunctionComponent<{}> = (props: {}) => {
    const account_result:QueryResult<AccountMeResult> = useAccountQuery();
    const navigate = useNavigate();

    if(account_result.isPending) {
        return <div>Loading...</div>;
    }

    if(account_result.isError) {
        return <div>Error: {account_result.error.toString()}</div>;
    }
    const services:Array<AIService> = account_result.data.services;
    const providers:Array<AIProvider> = account_result.data.providers;
    const models:Array<AIModel> = account_result.data.models;
    return <Space direction="vertical" style={{width:"100%"}}>
        <h1>AI Providers</h1>
        <ProviderTable providers={providers} navigate={navigate} />
        <div style={{width: "100%", height:"50px"}}>
            <Button type="primary"
                    onClick={()=>navigate("/providers/new")}
                    style={{float:"right"}}>
                Add New Provider
            </Button>
        </div>
        <h1>Models</h1>
        <ModelTable models={models} navigate={navigate} />
        <div style={{width: "100%", height:"50px"}}>
            <Button type="primary"
                    onClick={()=>navigate("/models/new")}
                    style={{float:"right"}}>
                Add New Model
            </Button>
        </div>
        <h1>Services</h1>
        <ServiceTable services={services} navigate={navigate} />
        <div style={{width: "100%", height:"50px"}}>
            <Button type="primary"
                    onClick={()=>navigate("/services/new")}
                    style={{float:"right"}}>
                Add New Service
            </Button>
        </div>
    </Space>
}
