import {Button, Form, FormInstance, Input, Space} from "antd";
import {AllowedOutputType} from "../../services";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import React from "react";


type ServiceOutputEnumFormListProps = {
    name: string[],
    path: any[],
    form: FormInstance<any>;
    value?: string[];
    onChange?: (value: string[]) => void;
}

export const ServiceOutputEnumFormList: React.FC<ServiceOutputEnumFormListProps> = (props: ServiceOutputEnumFormListProps) => {
    // grab the closest type declaration to this enum
    const type_field_name = props.path.slice(0, props.path.length - 1);
    const output_type = props.form.getFieldValue([...type_field_name, "type"]);
    //const output_type = Form.useWatch([...type_field_name, "type"], props.form);
    console.log("ServiceOutputEnumFormList::type_field_name", type_field_name, output_type)
    console.log("ServiceOutputEnumFormList:props", props);

    if (![AllowedOutputType.String, AllowedOutputType.Integer, AllowedOutputType.Float].includes(output_type)) {
        throw new Error("ServiceOutputEnumFormList should not render if the output type is not a string, integer, or float");
    }

    return (
        <Form.List name={props.name}>
            {(fields, {add, remove}, {errors}) => (
                <>
                    {fields.map((field, index) => (
                        <Space direction="horizontal"
                               style={{width: "100%", paddingTop: "4px", paddingBottom: "4px"}}
                               key={field.key}
                        >
                            <Form.Item {...field}>
                                <Input
                                    placeholder="Enum Value"
                                    type={output_type === AllowedOutputType.String ? "text" : "number"}
                                    style={{width: '100%'}}
                                />
                            </Form.Item>
                            <Button icon={<MinusCircleOutlined/>}
                                    onClick={() => remove(field.name)}
                                    danger={true}>
                                Remove
                            </Button>
                        </Space>
                    ))}

                    <Form.ErrorList errors={errors}/>

                    <Form.Item>
                        <Button
                            type="default"
                            onClick={() => add("")}
                            style={{width: '90%'}}
                            icon={<PlusOutlined/>}
                        >
                            Add Enum
                        </Button>
                    </Form.Item>
                </>
            )}
        </Form.List>
    )
}
