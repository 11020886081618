import React, {FunctionComponent} from "react";
import {Button, Form, Input, Select, Table, Alert, Space, Collapse} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import Title from "antd/lib/typography/Title";
import {
    useProviderListQuery,
    useProviderModelListQuery,
    useProviderMutation,
    useProviderQuery,
    AIProvider
} from "../services";
import {ValidateErrorEntity} from "rc-field-form/lib/interface";
import {Responses} from "../utils/requests";
import {getError, hasError, isPending} from "../utils/errors";
import {colNumberSort } from "../utils/sort";

type FormType = {
    label?: string;
    provider?: string;
    api_key?: string;
};

type ProviderFormProps = {
    initialValues: FormType,
    onFinish: ((values: any) => void) | undefined,
    onFinishFailed: ((errorInfo: ValidateErrorEntity<any>) => void) | undefined,
}

const ProviderForm: React.FC<ProviderFormProps> = ({
                                                       initialValues,
                                                       onFinish,
                                                       onFinishFailed,
                                                   }) => {
    return <>
        <Form
            name="basic"
            labelCol={{span: 8}}
            wrapperCol={{span: 16}}
            style={{maxWidth: 600}}
            initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item<FormType>
                label="Label for Provider"
                name="label"
                rules={[{required: true, message: 'Please provide a provider name!'}]}
            >
                <Input/>
            </Form.Item>

            <Form.Item<FormType>
                label="Provider"
                name="provider"
                rules={[{required: true, message: 'Please provide a provider name!'}]}>
                <Select>
                    <Select.Option value="openai">OpenAI</Select.Option>
                    <Select.Option value="anyscale">Anyscale</Select.Option>
                    <Select.Option value="google">Google</Select.Option>
                    <Select.Option value="cohere">Cohere</Select.Option>
                    <Select.Option value="huggingface">Hugging Face</Select.Option>
                </Select>
            </Form.Item>

            <Form.Item<FormType>
                label="API Key"
                name="api_key"
                rules={[{required: true, message: 'Please provide an API key!'}]}
            >
                <Input type="password" />
            </Form.Item>

            <Form.Item wrapperCol={{offset: 8, span: 16}}>
                <Button type="primary" htmlType="submit">
                    Save
                </Button>
            </Form.Item>
        </Form>
    </>
}

export const NewProviderView: FunctionComponent<{}> = (props: {}) => {
    const provider_mutation = useProviderMutation(null);
    const navigate = useNavigate();

    if (provider_mutation.isPending) {
        return <div>Loading...</div>
    }

    function onFinish(values: any) {
        provider_mutation.mutate(values);
    }

    function onFinishFailed(errorInfo: any) {
        console.log(errorInfo);
    }

    return <>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Button onClick={() => navigate("/providers")}>Back</Button>
            <Title level={2}>Setup an AI Provider</Title>
            {provider_mutation.isError && (
                <Alert message={Responses.error_detail(provider_mutation)} type="error" />
            )}
            <ProviderForm initialValues={{}}
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
            />
        </Space>
    </>
}


export const ProviderView: FunctionComponent<{}> = (props: {}) => {
    const navigate = useNavigate();
    const {providerId} = useParams();
    if (!providerId) {
        throw new Error("No provider ID provided");
    }
    const pid = parseInt(providerId);
    const provider_query = useProviderQuery(pid);
    const provider_mutation = useProviderMutation(pid);
    const model_list_query = useProviderModelListQuery(pid);

    if (isPending(provider_mutation, provider_query, model_list_query)) {
        return <div>Loading...</div>
    }

    if(hasError(provider_query, model_list_query)) {
        return <Alert message={getError(provider_query, model_list_query)} type="error" />
    }

    function onFinish(values: any) {
        provider_mutation.mutate(values);
    }

    function onFinishFailed(errorInfo: any) {
        console.log(errorInfo);
    }

    console.log("provider_mutation", provider_mutation)

    return <>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Button onClick={() => navigate("/providers")}>Back</Button>
            <Title level={2}>Setup an AI Provider</Title>
            {provider_mutation.isError && (
                <Alert message={Responses.error_detail(provider_mutation)} type="error" />
            )}
            <ProviderForm initialValues={{
                label: provider_query.data?.label,
                provider: provider_query.data?.provider,
                api_key: provider_query.data?.api_key,
            }}
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
            />
            <Collapse>
                <Collapse.Panel header="Models using this Provider" key="1">
                    <Table dataSource={model_list_query.data} rowKey="id">
                        <Table.Column title="ID" dataIndex="id" key="id" />
                        <Table.Column title="Label" dataIndex="label" key="label" />
                        <Table.Column title="Details" key="select" render={(value, record:AIProvider, index) => {
                            return <Button onClick={() => {
                                navigate(`/models/${record.id}`)
                            }}>More Info</Button>
                        }} />
                    </Table>
                </Collapse.Panel>
            </Collapse>
        </Space>
    </>
}

export const ProviderListView: FunctionComponent<{}> = (props: {}) => {
    const provider_list_query = useProviderListQuery();
    const navigate = useNavigate();

    if (provider_list_query.isPending) {
        return <div>Loading...</div>
    }

    if (provider_list_query.isError) {
        return <div>Error: {provider_list_query.error.toString()}</div>
    }

    const providers: Array<AIProvider> = provider_list_query.data;
    return <>
        <h1>AI Providers</h1>
        <ProviderTable providers={providers} navigate={navigate} />
        <br/>
        <Button type="primary" onClick={() => navigate("/providers/new")}>Add New Provider</Button>
    </>
}

type ProviderTableProps = {
    providers: Array<AIProvider>,
    navigate: (value: string) => void
}

export const ProviderTable: FunctionComponent<ProviderTableProps> = (props: ProviderTableProps) => {
    return (<Table dataSource={props.providers} rowKey="id">
        <Table.Column title="ID" dataIndex="id" key="id" sorter={colNumberSort("id")} sortDirections={['descend', 'ascend']}/>
        <Table.Column title="Provider" dataIndex="provider" key="provider"/>
        <Table.Column title="Label" dataIndex="label" key="label"/>
        <Table.Column title="Details" key="select" render={(value, record: AIProvider, index) => {
            return <Button onClick={() => {
                props.navigate(`/providers/${record.id}`)
            }}>More Info</Button>
        }}/>
    </Table>);
}