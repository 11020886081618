import React, {useState} from "react";
import {AllowedInputType, ServiceInput} from "../services";
import { Select, Input, Checkbox, Row, Col } from "antd";
import {CheckboxChangeEvent} from "antd/es/checkbox";

type ServiceInputChangeFunc = (value: ServiceInput) => void;

type ServiceInputFormControlProps = {
    value?: ServiceInput;
    onChange?: ServiceInputChangeFunc;
}

function containsSpecialCharacters(str: string) {
    // Regular expression for characters other than letters, numbers, and underscores
    const regex = /[^A-Za-z0-9_]/;

    // Test the string against the regular expression
    return regex.test(str);
}

const RowStyle = {
    marginTop: "8px",
    marginBottom: "8px"
}

export function serviceInputValidator(_: any, value: { name?: string }){
    if(!value || !value.name) {
        return Promise.reject(new Error('The name is required'));
    }
    if(value.name.trim().length === 0) {
        return Promise.reject(new Error('The name is required'));
    }
    if (containsSpecialCharacters(value.name)) {
        return Promise.reject(new Error('The name can only contain letters, numbers, and underscores'));
    }
    return Promise.resolve();
}

export const ServiceInputFormControl: React.FC<ServiceInputFormControlProps> = (props: ServiceInputFormControlProps) => {
    console.log("Render ServiceInputFormControl", props.value);
    const [name, setName] = useState<string>(props.value?.name || "");
    const [
        input_type,
        setInputType
    ] = useState<AllowedInputType>(props.value?.type || AllowedInputType.String);
    const [
        required,
        setRequired
    ] = useState<boolean>(props.value?.required || true);

    function triggerChange(changedValues: Partial<ServiceInput>){
        const result:ServiceInput = {
            "name": name,
            "type": input_type,
            "required": required,
            ...changedValues
        };
        if (props.onChange) {
            props.onChange(result);
        }
    }

    function onInputChange(field:string) {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            setName(event.target.value);
            return triggerChange({"name": event.target.value})
        }
    }
    function onTypeChange(value: AllowedInputType) {
        setInputType(value);
        return triggerChange({"type": value})
    }
    function onCheckboxChange(e: CheckboxChangeEvent) {
        setRequired(e.target.checked);
        return triggerChange({"required": e.target.checked})
    }

    return <>
            <Row style={RowStyle}>
                <Col span={2}>
                    <label>Name</label>
                </Col>
                <Col span={22}>
                    <Input type="text" value={name} onChange={onInputChange("name")} />
                </Col>
            </Row>
            <Row style={RowStyle}>
                <Col span={2}>
                    <label>Type</label>
                </Col>
                <Col span={22}>
                    <Select value={input_type} onChange={onTypeChange}>
                        <Select.Option value={AllowedInputType.String}>String</Select.Option>
                        <Select.Option value={AllowedInputType.Integer}>Integer</Select.Option>
                        <Select.Option value={AllowedInputType.Float}>Float</Select.Option>
                        <Select.Option value={AllowedInputType.Boolean}>Boolean</Select.Option>
                        <Select.Option value={AllowedInputType.Date}>Date</Select.Option>
                    </Select>
                </Col>
            </Row>
            <Row style={RowStyle}>
                <Col span={2}>
                    <label>Required</label>
                </Col>
                <Col span={22}>
                    <Checkbox checked={required} onChange={onCheckboxChange} />
                </Col>
            </Row>
    </>;
}
