import {EnvironmentInterface} from "../environment";
import {OpenAPI, Token} from "./generated_client";
import {queryClient} from "../App";


export function configure_client(env:EnvironmentInterface) {
    console.log("Configuring client...")
    console.log(env);

    OpenAPI.BASE = env.API_URL;

    // @ts-ignore
    OpenAPI.TOKEN = async () => {
        const token: Token|undefined = queryClient.getQueryData(["userToken"]);
        if (!token) {
            // navigate("/auth/sign-in");
            return undefined;
        }
        return token.access_token;
    }
}

/* Don't need this immediately
function configure_dev(){

}

function configure_staging(){

}

function configure_prod(){

}
*/
