/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Account } from '../models/Account';
import type { AccountCreationResult } from '../models/AccountCreationResult';
import type { AccountMeResult } from '../models/AccountMeResult';
import type { AIModel } from '../models/AIModel';
import type { AIModelVersion } from '../models/AIModelVersion';
import type { AIProvider } from '../models/AIProvider';
import type { AIProviderDetails } from '../models/AIProviderDetails';
import type { AIService } from '../models/AIService';
import type { AISubmission } from '../models/AISubmission';
import type { IncomingUser } from '../models/IncomingUser';
import type { LoginUser } from '../models/LoginUser';
import type { Token } from '../models/Token';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * Login
     * @param requestBody
     * @returns Token Successful Response
     * @throws ApiError
     */
    public static loginLoginPost(
        requestBody: LoginUser,
    ): CancelablePromise<Token> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Refresh
     * @returns Token Successful Response
     * @throws ApiError
     */
    public static refreshRefreshGet(): CancelablePromise<Token> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/refresh',
        });
    }

    /**
     * Get Me
     * @returns AccountMeResult Successful Response
     * @throws ApiError
     */
    public static getMeAccountsMeGet(): CancelablePromise<AccountMeResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/accounts/me/',
        });
    }

    /**
     * Create Account And User
     * @param requestBody
     * @returns AccountCreationResult Successful Response
     * @throws ApiError
     */
    public static createAccountAndUserAccountsPost(
        requestBody: IncomingUser,
    ): CancelablePromise<AccountCreationResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/accounts/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create User For Account
     * @param accountId
     * @param requestBody
     * @returns AccountCreationResult Successful Response
     * @throws ApiError
     */
    public static createUserForAccountAccountsAccountIdUsersPost(
        accountId: number,
        requestBody: IncomingUser,
    ): CancelablePromise<AccountCreationResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/accounts/{account_id}/users/',
            path: {
                'account_id': accountId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Account
     * @param accountId
     * @param requestBody
     * @returns AccountMeResult Successful Response
     * @throws ApiError
     */
    public static updateAccountAccountsAccountIdPost(
        accountId: number,
        requestBody: Account,
    ): CancelablePromise<AccountMeResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/accounts/{account_id}',
            path: {
                'account_id': accountId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Ai Services
     * @returns AIService Successful Response
     * @throws ApiError
     */
    public static readAiServicesServicesGet(): CancelablePromise<Array<AIService>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/services/',
        });
    }

    /**
     * Create Ai Service
     * @param requestBody
     * @param generate
     * @returns AIService Successful Response
     * @throws ApiError
     */
    public static createAiServiceServicesPost(
        requestBody: AIService,
        generate: boolean = false,
    ): CancelablePromise<AIService> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/services/',
            query: {
                'generate': generate,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Ai Service
     * @param serviceId
     * @returns AIService Successful Response
     * @throws ApiError
     */
    public static readAiServiceServicesServiceIdGet(
        serviceId: number,
    ): CancelablePromise<AIService> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/services/{service_id}',
            path: {
                'service_id': serviceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Ai Service
     * @param serviceId
     * @param requestBody
     * @returns AIService Successful Response
     * @throws ApiError
     */
    public static updateAiServiceServicesServiceIdPost(
        serviceId: number,
        requestBody: AIService,
    ): CancelablePromise<AIService> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/services/{service_id}',
            path: {
                'service_id': serviceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Run Ai Service
     * @param serviceId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static runAiServiceServicesServiceIdRunPost(
        serviceId: number,
        requestBody: Record<string, any>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/services/{service_id}/run/',
            path: {
                'service_id': serviceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Service Openapi Docs
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getServiceOpenapiDocsServicesServiceIdOpenapiGet(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/services/{service_id}/openapi',
        });
    }

    /**
     * Get Service Docs
     * @param serviceId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getServiceDocsServicesServiceIdDocsGet(
        serviceId: number,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/services/{service_id}/docs',
            path: {
                'service_id': serviceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Ai Submissions
     * @param serviceId
     * @param offset
     * @param limit
     * @returns AISubmission Successful Response
     * @throws ApiError
     */
    public static listAiSubmissionsServicesServiceIdSubmissionsGet(
        serviceId: number,
        offset?: number,
        limit: number = 100,
    ): CancelablePromise<Array<AISubmission>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/services/{service_id}/submissions',
            path: {
                'service_id': serviceId,
            },
            query: {
                'offset': offset,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Ai Submission
     * @param serviceId
     * @param submissionId
     * @returns AISubmission Successful Response
     * @throws ApiError
     */
    public static readAiSubmissionServicesServiceIdSubmissionsSubmissionIdGet(
        serviceId: number,
        submissionId: number,
    ): CancelablePromise<AISubmission> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/services/{service_id}/submissions/{submission_id}',
            path: {
                'service_id': serviceId,
                'submission_id': submissionId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Ai Submission
     * @param serviceId
     * @param submissionId
     * @param requestBody
     * @returns AISubmission Successful Response
     * @throws ApiError
     */
    public static readAiSubmissionServicesServiceIdSubmissionsSubmissionIdPost(
        serviceId: number,
        submissionId: number,
        requestBody: AISubmission,
    ): CancelablePromise<AISubmission> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/services/{service_id}/submissions/{submission_id}',
            path: {
                'service_id': serviceId,
                'submission_id': submissionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Ai Models
     * @returns AIModel Successful Response
     * @throws ApiError
     */
    public static listAiModelsModelsGet(): CancelablePromise<Array<AIModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/models/',
        });
    }

    /**
     * Create Ai Model
     * @param requestBody
     * @returns AIModel Successful Response
     * @throws ApiError
     */
    public static createAiModelModelsPost(
        requestBody: AIModel,
    ): CancelablePromise<AIModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/models/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Ai Model
     * @param aiModelId
     * @returns AIModel Successful Response
     * @throws ApiError
     */
    public static getAiModelModelsAiModelIdGet(
        aiModelId: number,
    ): CancelablePromise<AIModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/models/{ai_model_id}',
            path: {
                'ai_model_id': aiModelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Ai Model
     * @param aiModelId
     * @param requestBody
     * @returns AIModel Successful Response
     * @throws ApiError
     */
    public static updateAiModelModelsAiModelIdPost(
        aiModelId: number,
        requestBody: AIModel,
    ): CancelablePromise<AIModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/models/{ai_model_id}',
            path: {
                'ai_model_id': aiModelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Train Ai Model
     * @param aiModelId
     * @returns AIModelVersion Successful Response
     * @throws ApiError
     */
    public static trainAiModelModelsAiModelIdFineTunePost(
        aiModelId: number,
    ): CancelablePromise<AIModelVersion> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/models/{ai_model_id}/fine-tune/',
            path: {
                'ai_model_id': aiModelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Available Ai Providers
     * @returns AIProviderDetails Successful Response
     * @throws ApiError
     */
    public static listAvailableAiProvidersAvailableProvidersGet(): CancelablePromise<Array<AIProviderDetails>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/available-providers/',
        });
    }

    /**
     * List Ai Providers
     * @returns AIProvider Successful Response
     * @throws ApiError
     */
    public static listAiProvidersProvidersGet(): CancelablePromise<Array<AIProvider>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/providers/',
        });
    }

    /**
     * Create Ai Provider
     * @param requestBody
     * @returns AIProvider Successful Response
     * @throws ApiError
     */
    public static createAiProviderProvidersPost(
        requestBody: AIProvider,
    ): CancelablePromise<AIProvider> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/providers/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Ai Provider
     * @param providerId
     * @returns AIProvider Successful Response
     * @throws ApiError
     */
    public static getAiProviderProvidersProviderIdGet(
        providerId: number,
    ): CancelablePromise<AIProvider> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/providers/{provider_id}',
            path: {
                'provider_id': providerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Ai Provider
     * @param providerId
     * @param requestBody
     * @returns AIProvider Successful Response
     * @throws ApiError
     */
    public static updateAiProviderProvidersProviderIdPost(
        providerId: number,
        requestBody: AIProvider,
    ): CancelablePromise<AIProvider> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/providers/{provider_id}',
            path: {
                'provider_id': providerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Ai Provider
     * @param providerId
     * @returns AIModel Successful Response
     * @throws ApiError
     */
    public static getAiProviderProvidersProviderIdModelsGet(
        providerId: number,
    ): CancelablePromise<Array<AIModel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/providers/{provider_id}/models',
            path: {
                'provider_id': providerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
