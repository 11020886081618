import React, {FunctionComponent} from "react";
import { Button, Form, Input } from 'antd';
import {useRegisterAccountMutation} from "../services";
import {useNavigate} from "react-router-dom"


type FieldType = {
  email?: string;
  password?: string;
  full_name?: string;
  phone?: string;
};

export const SignUpView:FunctionComponent<{}> = (props: {}) => {
    const navigate = useNavigate();
    const registerMutation = useRegisterAccountMutation();

    const onFinish = (values: any) => {
        console.log('Success:', values);
        registerMutation.mutate(values);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    if(registerMutation.isSuccess) {
        navigate("/");
        return <div>Registration Complete.</div>
    }

    if(registerMutation.isPending) {
        return <div>Setting up your account...</div>;
    }

    return <>
        <h1>Register</h1>
        { registerMutation.isError && (
            <div>Error: {registerMutation.error.message}</div>
        )}
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >

              <Form.Item<FieldType>
              label="Full Name"
              name="full_name"
              rules={[{ required: true, message: 'Please input your full name!' }]}
            >
              <Input />
            </Form.Item>

              <Form.Item<FieldType>
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Please input your email address!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item<FieldType>
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password />
            </Form.Item>

              <Form.Item<FieldType>
              label="Phone (Optional)"
              name="phone"
              rules={[{ required: false }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
    </>
}