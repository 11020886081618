import React, {FunctionComponent} from "react";
import {Button, Form, Input, Select, Typography} from "antd";
import {useNavigate} from "react-router-dom";
import {
    useModelListQuery,
    useCreateNewServiceMutation,
    AIService
} from "../services";
import {isPending} from "../utils/errors";
import {Responses} from "../utils/requests";
import { Alert } from "antd/lib";
const { Title } = Typography;

function model_select_option_func(model: any) {
    return {label: model.label, value: model.id};
}


type FormType = {
  name?: string;
  description?: string;
  ai_model_id?: string;
};

export const NewServiceView:FunctionComponent<{}> = (props: {}) => {
    const navigate = useNavigate();
    const createServiceMutation = useCreateNewServiceMutation();
    const model_query = useModelListQuery();

    const onFinish = async (values: any) => {
      console.log('Success:', values);
      createServiceMutation.mutate(values, {
          onSuccess: (data) => {
              const service:AIService = data;
              console.log("onSuccess: ", service);
              navigate(`/services/${service.id}`);
          },
          onError: (error) => {
              console.log("Create Service Error: ", error);
          }
      });
    };

    const onFinishFailed = (errorInfo: any) => {
      console.log('Failed:', errorInfo);
    };

    if(isPending(model_query, createServiceMutation)){
        return <div>Loading...</div>
    }

    return <>
    {createServiceMutation.isError && (
            <Alert message={Responses.error_detail(createServiceMutation)} type="error" />
    )}
         <Title level={2}>Describe the new Service</Title>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item<FormType>
              label="Service Name"
              name="name"
              rules={[{ required: true, message: 'Please provide a service name!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item<FormType>
              label="Description"
              name="description"
              rules={[{ required: true, message: 'Please provide a service description!' }]}
            >
              <Input.TextArea />
            </Form.Item>

              <Form.Item<FormType>
                label="AI Model"
                name="ai_model_id"
                rules={[{ required: true, message: 'Please select an AI Model' }]}
              >
                <Select options={model_query.data?.map(model_select_option_func)} />
              </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
    </>
}
