
export function stringSort(a: string, b: string): number {
  return a.localeCompare(b)
}


export function colStringSort(prop: string){
    return function(a:{ [id: string] : string; }, b:{ [id: string] : string; }){
        return stringSort(a[prop], b[prop])
    }
}

export function numberSort(a: number, b: number): number {
  return a - b
}


export function colNumberSort(prop: string){
    return function(a:{ [id: string] : number; }, b:{ [id: string] : number; }){
        return numberSort(a[prop], b[prop])
    }
}