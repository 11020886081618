import React, {PropsWithChildren, useState} from "react";
import {Navigation} from "../components/Navigation";
import {Layout, theme} from "antd";

const {
    Header,
    Content,
    Footer,
    Sider
} = Layout;


export const StandardLayout = ({ children }: PropsWithChildren) => {
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: {colorBgContainer},
    } = theme.useToken();

    return <Layout style={{minHeight: '100vh'}}>
        <Sider collapsible
               collapsed={collapsed}
               onCollapse={(value) => setCollapsed(value)}>
            <div className="demo-logo-vertical"/>
            <Navigation />
        </Sider>
        <Layout>
            <Header style={{padding: 0, background: colorBgContainer}}/>
            <Content style={{margin: '0 16px'}}>
                {children}
            </Content>
            <Footer style={{textAlign: 'center'}}>Todd Cullen ©2023</Footer>
        </Layout>
    </Layout>
}