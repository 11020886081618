

export class Responses {
    static error_detail(mutation: any): string {
        // @ts-ignore
        if (mutation.error.response) {
            // @ts-ignore
            return mutation.error.response.data.detail;
        } else {
            // @ts-ignore
            return mutation.error.message;
        }
    }
}