import {QueryResult} from "./types";
import {AISubmission, DefaultService} from "./generated_client";
import {useRequireToken} from "./auth";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getQueryKey as getServiceQueryKey} from "./ai_service";


function getQueryKey(serviceId:number, submissionId ?:number) {
    const result = getServiceQueryKey(serviceId);
    if(submissionId){
        return result.concat(["submission", submissionId]);
    }
    return result;
}


export function useTrainingListQuery(serviceId:number):QueryResult<AISubmission[]>{
    useRequireToken();

    return useQuery({
        queryKey: getQueryKey(serviceId),
        queryFn: async () => {
            const response = await DefaultService.listAiSubmissionsServicesServiceIdSubmissionsGet(serviceId);
            return response;
        }
    })
}

export function useTrainingGetQuery(
    serviceId:number,
    trainingId:number
):QueryResult<AISubmission>{
    useRequireToken();

    return useQuery({
        queryKey: getQueryKey(serviceId, trainingId),
        queryFn: async () => {
            const response = await DefaultService.readAiSubmissionServicesServiceIdSubmissionsSubmissionIdGet(serviceId, trainingId);
            return response;
        }
    })
}

export function useTrainingMutation(serviceId:number, trainingId:number) {
    useRequireToken();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (training: AISubmission) => {
            const response = await DefaultService.readAiSubmissionServicesServiceIdSubmissionsSubmissionIdPost(serviceId, trainingId, training);
            await queryClient.invalidateQueries({
                queryKey: getQueryKey(serviceId, trainingId),
            });
            queryClient.setQueryData(getQueryKey(serviceId, trainingId), response);
            return response;
        },
    });
}