export function updateObject(obj: object, newValues: object) {
    return { ...obj, ...newValues }
}

export type Option = {
    value: string,
    label: string
}


export function optionsFromEnum<O extends object>(e:O):Option[]{

    let result: Option[] = []
    for (const tl in e) {
        const value:string = e[tl] as string;
        result.push({
            value: value,
            label: value
        })
    }
    return result;
}