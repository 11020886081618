import {OpenAIProviderModelSettings} from "../services";
import React from "react";
import {Button, Form, Input} from "antd";


type OpenAIProps = {
    initialValues: OpenAIProviderModelSettings;
    onFinish: (values: OpenAIProviderModelSettings) => void;
    onFinishFailed: (errorInfo: any) => void;
}

export const OpenAIProviderSettingsForm: React.FC<OpenAIProps> = ({
    initialValues,
    onFinish,
    onFinishFailed
}) => {

    return (
        <>
            <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item<OpenAIProviderModelSettings>
              label="Name of OpenAI Base Model"
              name="base_model_name"
              rules={[{ required: false }]}
            >
              <Input />
            </Form.Item>

            <Form.Item<OpenAIProviderModelSettings>
              label="Temperature"
              name="temperature"
              rules={[{ required: false}]}>
                <Input />
            </Form.Item>

                <Form.Item<OpenAIProviderModelSettings>
              label="MaxTokens"
              name="max_tokens"
              rules={[{ required: false}]}>
                <Input />
            </Form.Item>

                <Form.Item<OpenAIProviderModelSettings>
              label="FrequencyPenalty"
              name="frequency_penalty"
              rules={[{ required: false}]}>
                <Input />
            </Form.Item>

                <Form.Item<OpenAIProviderModelSettings>
              label="PresencePenalty"
              name="presence_penalty"
              rules={[{ required: false}]}>
                <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </>
    );
}




