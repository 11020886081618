import {Menu, MenuProps} from "antd";
import React from "react";
import {DesktopOutlined, FileOutlined, UserOutlined, FunctionOutlined, MoneyCollectOutlined} from "@ant-design/icons";
import {SelectInfo} from "rc-menu/lib/interface";
import {Params, useMatch, useNavigate, useParams} from "react-router-dom";
import {
    AIModel,
    AIProvider,
    AIService,
    QueryResult,
    useServiceListQuery,
    useProviderListQuery,
    useModelListQuery
} from "../services";
type MenuItem = Required<MenuProps>['items'][number];

type Screen = {
    label: string,
    icon?: React.ReactNode;
    urls: string[],
    menu_url: (info: SelectInfo) => string,
    match_selection?: (params:Readonly<Params<string>>) => string[],
}

type TopLevelScreen = Screen & {
    child?: ChildLevelScreen;
    key: string;
}

type ChildLevelScreen = Screen & {
    data: string;
    keyMatch: RegExp;
}

const HomeScreen:TopLevelScreen = {
    label: 'Home',
    key: '1',
    icon: <DesktopOutlined />,
    urls: ['/'],
    menu_url: (info: SelectInfo) => {
        return "/";
    },
};

const ProviderScreen:ChildLevelScreen = {
    data: "providers",
    label: 'AI Provider - Dynamically replaced',
    keyMatch: /^providers-/,
    icon: <FileOutlined />,
    urls: ['/providers/:providerId'],
    menu_url: (info: SelectInfo) => {
        console.log("creating url for provider: ", info.keyPath[0]);
        return "/providers/" + info.keyPath[0].substring(10);
    },
    match_selection: (params:Readonly<Params<string>>) => {
        if(!ProviderListScreen.key) throw new Error("ProviderListScreen.key is undefined");
        if(!params.providerId) return [ProviderListScreen.key];
        return [ProviderListScreen.key, "providers-" + params.providerId];
    }
};

const ProviderListScreen:TopLevelScreen = {
    label: 'AI Providers',
    key: '2',
    child: ProviderScreen,
    icon: <DesktopOutlined />,
    urls: ['/providers'],
    menu_url: (info: SelectInfo) => {
        return "/providers";
    },
};

const ModelScreen:ChildLevelScreen = {
    label: 'AI Models',
    keyMatch: /^models-/,
    data: "models",
    icon: <FileOutlined />,
    urls: ['/models/:modelId'],
    menu_url: (info: SelectInfo) => {
        return "/models/" + info.keyPath[0].substring(7);
    },
    match_selection: (params:Readonly<Params<string>>) => {
        if(!ModelListScreen.key) throw new Error("ModelListScreen.key is undefined");
        if(!params.modelId) return [ModelListScreen.key];
        return [ModelListScreen.key, "models-" + params.modelId];
    }
};

const ModelListScreen:TopLevelScreen = {
    label: 'AI Models',
    key: '3',
    child: ModelScreen,
    icon: <FileOutlined />,
    urls: ['/models'],
    menu_url: (info: SelectInfo) => {
        return "/models";
    },
};

const ServiceScreen:ChildLevelScreen = {
    label: 'Services',
    keyMatch: /^services-/,
    data: "services",
    icon: <FileOutlined />,
    urls: ['/services/:serviceId'],
    menu_url: (info: SelectInfo) => {
        return `/services/${info.keyPath[0].substring(9)}`;
    },
    match_selection: (params:Readonly<Params<string>>) => {
        if(!ServiceListScreen.key) throw new Error("ServiceListScreen.key is undefined");
        if(!params.serviceId) return [ServiceListScreen.key];
        return [ServiceListScreen.key, "services-" + params.serviceId];
    }
};

const ServiceListScreen:TopLevelScreen = {
    label: 'Services',
    key: '4',
    child: ServiceScreen,
    icon: <FileOutlined />,
    urls: ['/services'],
    menu_url: (info: SelectInfo) => {
        return `/services`;
    },
};

const AccountScreen:TopLevelScreen = {
    label: 'Account',
    key: '5',
    icon: <UserOutlined />,
    urls: ['/account'],
    menu_url: (info: SelectInfo) => {
        return "/account";
    },
};

const BillingScreen:TopLevelScreen = {
    label: 'Billing',
    key: '6',
    icon: <MoneyCollectOutlined />,
    urls: ['/billing'],
    menu_url: (info: SelectInfo) => {
        return "/billing";
    },
};

const LogoutScreen:TopLevelScreen = {
    label: 'Log Out',
    key: '7',
    icon: <FileOutlined />,
    urls: ['/logout'],
    menu_url: (info: SelectInfo) => {
        return "/logout";
    }
};

const screens:TopLevelScreen[] = [
    HomeScreen,
    ProviderListScreen,
    ModelListScreen,
    ServiceListScreen,
    AccountScreen,
    BillingScreen,
    LogoutScreen,
];

function findScreenForKey(key:string):TopLevelScreen|ChildLevelScreen {
    console.log("Finding screen for key", key);
    for( let screen of screens ) {
        console.log("checking against: ", screen.key);
        if (screen.key === key) {
            console.log("Found screen for key", key);
            return screen;
        }
        if(screen.child){
            console.log("checking against child: ", screen.child.keyMatch);
            if(screen.child.keyMatch.test(key)){
                console.log("Found screen for key", key);
                return screen.child;
            }
        }
    }
    throw new Error("Screen not found");
}

/*
function findParentScreenForChild(child:ChildLevelScreen):TopLevelScreen {
    for( let screen of screens ) {
        if(screen.child === child){
            return screen;
        }
    }
    throw new Error("Parent screen not found");
}
*/


function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}


function useSelectedScreen():TopLevelScreen|ChildLevelScreen{
    let currentScreen:TopLevelScreen|ChildLevelScreen = screens[0];
    screens.forEach((screen, index, a) => {
        screen.urls.forEach((url, index, a) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const match = useMatch(url);
            console.log("match", url, match);
            if(match){
                currentScreen = screen;
            }
        });
        if(screen.child){
            screen.child?.urls.forEach((url, index, a) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const match = useMatch(url);
                console.log("match", url, match);
                if(match){
                    currentScreen = screen.child as ChildLevelScreen;
                }
            })
        }
    });
    return currentScreen;
}


function createMenuArray(providers: AIProvider[], models: AIModel[], services:AIService[]): MenuItem[] {
    let top_level_items: MenuItem[] = screens.map((screen, index, a) => {
        if(screen.child){
            if(screen.child.data === "providers") {
                const children:MenuItem[] = providers.map((provider, index, a) => {
                    return getItem(provider.label, "providers-"+provider.id, <FunctionOutlined />);
                });
                return getItem(screen.label, screen.key, screen.icon, children);
            }else if(screen.child.data === "models"){
                const children:MenuItem[] = models.map((model, index, a) => {
                    return getItem(model.label, "models-"+model.id, <FunctionOutlined />);
                });
                return getItem(screen.label, screen.key, screen.icon, children);
            }else if(screen.child.data === "services") {
                const children:MenuItem[] = services.map((service, index, a) => {
                    return getItem(service.name, "services-"+service.id, <FunctionOutlined />);
                });
                return getItem(screen.label, screen.key, screen.icon, children);
            }else{
                throw new Error("Unknown data type");
            }
        }
        return getItem(screen.label, screen.key, screen.icon);
    });

    return top_level_items;
}


export function Navigation(props:{}):JSX.Element {
    const currentScreen: TopLevelScreen|ChildLevelScreen = useSelectedScreen();
    const params:Readonly<Params<string>> = useParams();
    const navigate = useNavigate();

    const service_list_result:QueryResult<AIService[]> = useServiceListQuery();
    const provider_list_result:QueryResult<AIProvider[]> = useProviderListQuery();
    const model_list_result:QueryResult<AIModel[]> = useModelListQuery();

    let defaultSelectedKeys:string[];
    if(currentScreen.match_selection){
        defaultSelectedKeys = currentScreen.match_selection(params);
    }else{

        defaultSelectedKeys = [(currentScreen as TopLevelScreen).key];
    }

    console.log("defaultSelectedKeys", defaultSelectedKeys);

    const onSelectCallback = (info: SelectInfo) => {
        console.log("onSelectCallback", info)
        if(!info.keyPath || info.keyPath.length === 0) return;
        if(info.keyPath.length === 1){
            const screen = findScreenForKey(info.keyPath[0]);
            const url = screen.menu_url(info);
            console.log("Navigating to", screen, url);
            return navigate(url);
        }
        const screen:ChildLevelScreen = findScreenForKey(info.keyPath[0]) as ChildLevelScreen;
        const url = screen.menu_url(info);
        console.log("Navigating to", screen, url);
        return navigate(url);
    }

    if(service_list_result.isPending || provider_list_result.isPending || model_list_result.isPending){
        return <div>Loading...</div>;
    }

    if(service_list_result.isError || provider_list_result.isError || model_list_result.isError){
        return <div>Error. Reload page.</div>;
    }

    const menu_items:MenuItem[] = createMenuArray(provider_list_result.data, model_list_result.data, service_list_result.data);

    return <Menu theme="dark"
                 selectedKeys={defaultSelectedKeys}
                 mode="inline"
                 items={menu_items}
                 onSelect={onSelectCallback} />;
}

