import React, {useState} from "react";
import {Button, Collapse, Table, Alert, Form, Space, Tabs, TabsProps} from "antd";
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import Editor from 'react-simple-code-editor';
// @ts-ignore
import {highlight, languages} from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-markup-templating';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-django';
import 'prismjs/themes/prism.css';
import {useNavigate, useParams} from "react-router-dom";
import {Responses} from "../utils/requests";
import {ServiceRunForm} from "../components/forms/ServiceRunForm";
import {ServiceInputFormControl, serviceInputValidator} from "../components/ServiceInput";
import {ServiceOutputDictFormList} from "../components/forms/service_output_dict_form_list";
import {colNumberSort} from "../utils/sort";
import {
    AIService,
    AISubmission,
    ServiceDefinition,
    ServiceInput,
    ServiceOutput,
    empty_service_definition,
    useServiceDocsQuery,
    useServiceRunMutation,
    useServiceMutation,
    useServiceQuery,
    useTrainingListQuery
} from "../services";

type InputFormType = {
    inputs: ServiceInput[]
}
type InputViewProps = {
    service: AIService
}
const InputView = (props: InputViewProps) => {
    if(!props.service.id) throw new Error("Service ID is required");
    const service_mutation = useServiceMutation(props.service.id);
    const service_definition:ServiceDefinition = props.service.service_definition as ServiceDefinition;
    const [inputs, setInputs] = useState<ServiceInput[]>(service_definition.inputs);
    const [error, setError] = useState<string | null>(null);

    function onSave(values: InputFormType) {
        const service: AIService = props.service;
        if(!service.service_definition) service.service_definition = empty_service_definition();
        service.service_definition.inputs = values.inputs;
        service_mutation.mutate(service);
        setInputs(values.inputs);
        setError(null)
    }

    if (service_mutation.isPending) {
        return <Alert message="Saving..." type="info"/>;
    }
    return (<>
        <Space direction="vertical" style={{width: "100%"}}>
            {!!error && (
                <Alert message={error} type="error"/>
            )}
            {service_mutation.isError && (
                <Alert message={Responses.error_detail(service_mutation)} type="error"/>
            )}
            {service_mutation.isSuccess && (
                <Alert message="Saved!" type="success"/>
            )}
            <Form initialValues={{"inputs": inputs}}
                  onFinish={onSave}>
                <Form.List
                    name="inputs">
                    {(fields, {add, remove}, {errors}) => (
                        <>
                            {fields.map((field, index) => (
                                <div style={{border: "1px dashed #ccc", padding: "8px", margin: "8px"}} key={field.key}>
                                    <Form.Item
                                        label=""
                                        required={false}
                                        rules={[{validator: serviceInputValidator}]}
                                        {...field}
                                    >
                                        <ServiceInputFormControl/>
                                    </Form.Item>
                                    {fields.length > 1 ? (
                                        <Button danger={true}
                                                icon={<MinusCircleOutlined/>}
                                                onClick={() => remove(field.name)}
                                                style={{margin: "4px"}}
                                        >
                                            Remove
                                        </Button>
                                    ) : null}
                                </div>
                            ))}

                            <Form.ErrorList errors={errors}/>

                            <Form.Item>
                                <Button
                                    type="default"
                                    onClick={() => add({
                                        "name": "",
                                        "type": "string",
                                        "required": true
                                    }, fields.length)}
                                    style={{width: '60%', marginLeft: "8px"}}
                                    icon={<PlusOutlined/>}
                                >
                                    Add Input
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{marginLeft: "8px"}}>
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </Space>
    </>)
}

type OutputFormType = {
    outputs: ServiceOutput[]
}

type OutputViewProps = {
    service: AIService
}
const OutputView = (props: OutputViewProps) => {
    if(!props.service.id) throw new Error("Service is required");
    const service_mutation = useServiceMutation(props.service.id);
    const [error, setError] = useState<string | null>(null);
    const [form] = Form.useForm();

    function onSave(values: OutputFormType) {
        const service: AIService = props.service;
        if(!service.service_definition) service.service_definition = empty_service_definition();
        service.service_definition.outputs = values.outputs;
        service_mutation.mutate(service);
        setError(null)
    }

    return (<Space direction="vertical" style={{width: "100%"}}>
        {!!error && (
            <Alert message={error} type="error"/>
        )}
        {service_mutation.isError && (
            <Alert message={Responses.error_detail(service_mutation)} type="error"/>
        )}
        {service_mutation.isSuccess && (
            <Alert message="Saved!" type="success"/>
        )}
        <Form form={form}
              initialValues={{"outputs": props.service.service_definition?.outputs}}
              onFinish={onSave}>
            <Form.Item name="outputs">
                <ServiceOutputDictFormList name={["outputs"]} path={["outputs"]} form={form} />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Save
                </Button>
            </Form.Item>
        </Form>
    </Space>)
}

type PromptViewProps = {
    service: AIService
}
const PromptView = (props: PromptViewProps) => {
    if(!props.service.id) throw new Error("Service is required");
    const service_definition:ServiceDefinition = props.service.service_definition as ServiceDefinition;
    const service_mutation = useServiceMutation(props.service.id);
    const [prompt, setPrompt] = useState<string>(service_definition.prompt_template);

    function onSave() {
        const service: AIService = props.service;
        if(!service.service_definition) service.service_definition = empty_service_definition();
        service.service_definition.prompt_template = prompt;
        service_mutation.mutate(service);
    }

    return (<>
        {service_mutation.isError && (
            <Alert message={Responses.error_detail(service_mutation)} type="error"/>
        )}
        {service_mutation.isSuccess && (
            <Alert message="Saved!" type="success"/>
        )}
        <div style={{margin: "10px"}}>
            Use the editor below to provide the prompt template to execute for the service.<br/>
            You can access the inputs provided to the service by using the <code>&#123;&#123; variable_name &#125;&#125;</code> syntax.<br/>
            For more advanced usage, see the <a target="_blank" rel="noreferrer" href="https://docs.djangoproject.com/en/4.2/topics/templates/#the-django-template-language">Django template documentation</a>.
        </div>
        <Editor
            value={prompt}
            onValueChange={(value) => setPrompt(value)}
            highlight={code => highlight(code, languages.django)}
            padding={10}
            style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 12,
                border: "1px solid #ccc",
                margin: "8px",
                minHeight: "200px"
            }}
        />
        <Button type="primary" onClick={onSave}>Save</Button>
    </>)
}

type TestingViewProps = {
    service: AIService,
}

const TestView = (props: TestingViewProps) => {
    if(!props.service.id) throw new Error("Service is required");
    const run_mutation = useServiceRunMutation(props.service.id);

    function onFinish(values: any) {
        run_mutation.mutate(values);
    }

    function onFinishFailed(errorInfo: any) {
        console.log(errorInfo);
    }

    function onReset(){
        run_mutation.reset();
    }

    return <>
        {run_mutation.isError && (
            <Alert message={Responses.error_detail(run_mutation)} type="error"/>
        )}


        <ServiceRunForm service={props.service}
                        onFinishFailed={onFinishFailed}
                        onFinish={onFinish}
                        onReset={onReset}
        />

        {run_mutation.isPending && (
            <Alert message="Executing request against LLM" type="info"/>
        )}

        {run_mutation.isSuccess && (
            <>
                <Alert message="Success!" type="success"/>
                <Editor
                    value={JSON.stringify(run_mutation.data, null, 4)}
                    highlight={code => highlight(code, languages.javascript)}
                    onValueChange={(value) => {}}
                    disabled={true}
                    padding={10}
                    style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 12,
                    }}
                />
            </>
        )}
    </>;
}


type TrainingViewProps = {
    service: AIService,
}

const TrainingView = (props: TrainingViewProps) => {
    if(!props.service.id) throw new Error("Service is required");
    const navigate = useNavigate();
    const training_result = useTrainingListQuery(props.service.id);

    if (training_result.isPending) {
        return <div>Loading...</div>;
    }
    if (training_result.isError) {
        return <div>Error: {training_result.error.toString()}</div>;
    }
    const training: Array<AISubmission> = training_result.data;
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: colNumberSort("id"),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend',
        },
        {
            title: 'Input',
            dataIndex: 'input',
            key: 'input',
            render: (text: string, record: AISubmission) => {
                return JSON.stringify(record.input, null, 2)
            }
        },
        {
            title: 'Output',
            dataIndex: 'output',
            key: 'output',
            render: (text: string, record: AISubmission) => {
                return JSON.stringify(record.output, null, 2)
            }
        },
        {
            title: 'Eval Status',
            dataIndex: 'evaluation_status',
            key: 'evaluation_status',
        },
        {
            title: 'Details',
            key: 'select',
            render: (value: any, record: AISubmission, index: number) => {
                return <Button onClick={() => {
                    navigate(`/services/${props.service.id}/submissions/${record.id}`)
                }}>More Info</Button>
            }
        }
    ];
    // @ts-ignores
    return <Table dataSource={training} rowKey="id" columns={columns} sortDirections={['descend', 'ascend']} />;
}

type DocumentationViewProps = {
    service: AIService
}

const DocumentationView = (props: DocumentationViewProps) => {
    if(!props.service.id) throw new Error("Service is required");
    const docs_query = useServiceDocsQuery(props.service.id);

    if(docs_query.isPending){
        return <Alert message="Loading..." type="info"/>;
    }

    if(docs_query.isError) {
        return <Alert message={docs_query.error.message} type="error"/>;
    }

    const tabs: TabsProps['items'] = [];
    for (let key in docs_query.data) {
        tabs.push({
            key: key,
            label: key,
            children: <Space direction="vertical">
                <Editor
                            // @ts-ignore
                            value={docs_query.data[key]}
                            highlight={code => highlight(code, languages.javascript)}
                            onValueChange={(value) => {}}
                            disabled={true}
                            padding={10}
                            style={{
                                fontFamily: '"Fira code", "Fira Mono", monospace',
                                fontSize: 12,
                            }}/>
                <Button type="primary" onClick={() => {} }>Copy</Button>
            </Space>
        })
    }

    return (<>
        <div>
            Select your preferred language below to see the API documentation for this service.
        </div>
        <Tabs defaultActiveKey="1"
              items={tabs}
              style={{padding: "8px"}} />

    </>);
}

export const ServiceView = (props: {}) => {
    const {serviceId} = useParams();
    const navigate = useNavigate();
    if (!serviceId) {
        navigate("/");
    }
    // @ts-ignore
    const service_result = useServiceQuery(serviceId);
    if (service_result.isPending) {
        return <div>Loading...</div>;
    }
    if (service_result.isError) {
        return <div>Error: {service_result.error.toString()}</div>;
    }
    const service: AIService = service_result.data;
    console.log("SERVICE", service);
    return (
        <>
            <h1>{service.name}</h1>
            <h2>{service.description}</h2>
            <Collapse defaultActiveKey={['1']} items={[
                {
                    key: '1',
                    label: 'Inputs',
                    children: <InputView service={service}/>,
                },
                {
                    key: '2',
                    label: 'Outputs',
                    children: <OutputView service={service}/>,
                },
                {
                    key: '3',
                    label: 'LLM Prompt',
                    children: <PromptView service={service}/>,
                },
                {
                    key: '4',
                    label: 'Test Service',
                    children: <TestView service={service}/>,
                },
                {
                    key: '5',
                    label: 'Training',
                    children: <TrainingView service={service}/>,
                },
                {
                    key: '6',
                    label: 'Quickstart Code',
                    children: <DocumentationView service={service}/>,
                },
            ]}/>
        </>
    );
}

type ServiceTableProps = {
    services: Array<AIService>,
    navigate: (path: string) => void
}

export const ServiceTable = (props: ServiceTableProps) => {
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: colNumberSort("id"),
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Details',
            key: 'select',
            render: (value: any, record: AIService, index: number) => {
                return <Button onClick={() => {
                    props.navigate(`/services/${record.id}`)
                }}>More Info</Button>
            }
        }
    ];
    // @ts-ignore
    return <Table dataSource={props.services} rowKey="id" columns={columns}/>;
}
