import Editor from "react-simple-code-editor";
import React, {FunctionComponent} from "react";

// @ts-ignore
import {highlight, languages} from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';

type JSONViewerProps = {
    data: any,
    style?: any,
}

export const JSONViewer: FunctionComponent<JSONViewerProps> = (props: JSONViewerProps) => {
    return <Editor
        value={JSON.stringify(props.data, null, 2)}
        onValueChange={(value) => {
        }}
        highlight={code => highlight(code, languages.javascript)}
        padding={10}
        disabled={true}
        style={{
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 12,
            backgroundColor: "#fff",
            ...props?.style
        }}
    />
}
