import {UseQueryResult, UseMutateFunction} from "@tanstack/react-query";
import {DefaultError} from "@tanstack/query-core/build/modern/index";


export type QueryResult<TData> = UseQueryResult<TData, DefaultError>

export type MutationResult<TData> = QueryResult<TData> & {
    mutate: UseMutateFunction<TData, DefaultError>
};

export type MutationSettledFn = UseMutateFunction;

export type SuccessCallbackFn = () => void;

export enum AllowedInputType {
    String = "string",
    Integer = "integer",
    Float = "float",
    Date = "date",
    Boolean = "boolean",
}

export type ServiceInput = {
    type: AllowedInputType,
    name: string,
    required: boolean
};

export enum AllowedOutputType {
    String = "string",
    Integer = "integer",
    Float = "float",
    Boolean = "boolean",
    Date = "date",
    Dict = "dict",
    List = "list"
}

export type OutputBase = {
    name: string,
    description: string,
    type: AllowedOutputType
}
export type OutputString = OutputBase & {
    type: AllowedOutputType.String,
    enum?: string[]
}
export type OutputInteger = OutputBase & {
    type: AllowedOutputType.Integer,
    enum?: number[]
}
export type OutputFloat = OutputBase & {
    type: AllowedOutputType.Float,
    enum?: number[]
}

export type OutputDate = OutputBase & {
    type: AllowedOutputType.Date,
}

export type OutputBoolean = OutputBase & {
    type: AllowedOutputType.Boolean,
}
export type OutputDict = OutputBase & {
    type: AllowedOutputType.Dict,
    items: OutputBase[]
}
export type OutputList = OutputBase & {
    type: AllowedOutputType.List,
    item: OutputBase
}
export type ServiceOutput = OutputList | OutputDict | OutputDate | OutputBoolean | OutputInteger | OutputFloat | OutputString;

export type ServiceDefinition = {
    inputs: ServiceInput[],
    outputs: ServiceOutput[],
    prompt_template: string
}

export function empty_service_definition(){
    return {
        inputs: [],
        outputs: [],
        prompt_template: ""
    }
}