import React from "react";
import {Select, Input, Space, Row, Col, Form, FormInstance} from "antd";
import {optionsFromEnum} from "../../utils/form";
import TextArea from "antd/lib/input/TextArea";
import {
    OutputBase,
    AllowedOutputType,
    ServiceOutput
} from "../../services";
import {ServiceOutputDictFormList} from "./service_output_dict_form_list";
import {ServiceOutputEnumFormList} from "./service_output_enum_form_list";

const ENUM_TYPES = [
    AllowedOutputType.String,
    AllowedOutputType.Integer,
    AllowedOutputType.Float
];

type ServiceOutputChangeFunc = (value: ServiceOutput) => void;

type ServiceOutputFormControlProps = {
    form: FormInstance<any>;
    name: any[];
    path: any[];
    value?: OutputBase;
    onChange?: ServiceOutputChangeFunc;
}

function containsSpecialCharacters(str: string) {
    // Regular expression for characters other than letters, numbers, and underscores
    const regex = /[^A-Za-z0-9_]/;

    // Test the string against the regular expression
    return regex.test(str);
}

export function serviceOutputValidator(_: any, value: { name?: string }) {
    if (!value || !value.name) {
        return Promise.reject(new Error('The name is required'));
    }
    if (containsSpecialCharacters(value.name)) {
        return Promise.reject(new Error('The name can only contain letters, numbers, and underscores'));
    }
    return Promise.resolve();
}

export const ServiceOutputFormControl: React.FC<ServiceOutputFormControlProps> = (
    props: ServiceOutputFormControlProps
) => {
    const output_type = props.value?.type || AllowedOutputType.String;
    console.log("ServiceOutputFormControl", props, output_type);
    return (
        <Space direction="vertical" style={{width: "100%"}}>
            <Row>
                <Col span={4}><label>Name</label></Col>
                <Col span={20}>
                    <Form.Item name={[...props.name, "name"]}>
                        <Input
                            placeholder="Name"
                            type="text"
                            style={{width: '100%'}}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={4}><label>Type</label></Col>
                <Col span={20}>
                    <Form.Item name={[...props.name, "type"]}>
                        <Select
                            placeholder="Output type"
                            style={{width: '100%'}}
                            options={optionsFromEnum(AllowedOutputType)}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={4}><label>Description</label></Col>
                <Col span={20}>
                    <Form.Item name={[...props.name, "description"]}>
                        <TextArea
                            placeholder="Output description"
                            style={{width: '100%', height: "100px"}}/>
                    </Form.Item>
                </Col>
            </Row>

            {output_type === AllowedOutputType.List && (
                <Row>
                    <Col span={4}><label>Items in List:</label></Col>
                    <Col span={20}>
                        <div style={{
                            border: "1px dashed #ccc",
                            padding: "8px",
                            margin: "8px",
                            width: "100%"
                        }}>
                            <Form.Item name={[...props.name, "item"]}>
                                <ServiceOutputFormControl form={props.form}
                                                          name={[...props.name, "item"]}
                                                          path={[...props.path, "item"]}/>
                            </Form.Item>
                        </div>
                    </Col>

                </Row>
            )}

            {output_type === AllowedOutputType.Dict && (
                <Row>
                    <Col span={4}><label>Items in Dictionary:</label></Col>
                    <Col span={20}>
                        <Form.Item name={[...props.name, "items"]}>
                            <ServiceOutputDictFormList path={[...props.path, "items"]}
                                                       name={[...props.name, "items"]}
                                                       form={props.form}/>
                        </Form.Item>
                    </Col>
                </Row>
            )}

            {ENUM_TYPES.includes(output_type) && (
                <Row>
                    <Col span={4}><label>Enum Values:</label></Col>
                    <Col span={20}>
                        <Form.Item name={[...props.name, "enum"]}>
                            <ServiceOutputEnumFormList path={[...props.path, "enum"]}
                                                       name={[...props.name, "enum"]}
                                                       form={props.form}/>
                        </Form.Item>
                    </Col>
                </Row>
            )}

        </Space>
    );
}