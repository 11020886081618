import React, {PropsWithChildren} from "react";
import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";
import {useRequireToken} from "./services";
import {
    HomeView,
    NewProviderView,
    ServiceView,
    LoginView,
    SignUpView,
    AITrainingView,
    NewServiceView,
    ProviderListView,
    ProviderView,
    ModelListView,
    NewModelView,
    ModelView
} from "./views";
import {StandardLayout} from "./layouts/standard";

function ProtectedRoute({children}: PropsWithChildren) {
    useRequireToken();
    return <>{children}</>
}

const raw_routes = [
    {
        path: "/",
        element:
            <ProtectedRoute>
                <React.Suspense fallback={'Loading...'}>
                    <StandardLayout>
                        <HomeView/>
                    </StandardLayout>
                </React.Suspense>
            </ProtectedRoute>
        ,
    },
    {
        path: "/models",
        element: <ProtectedRoute>
            <React.Suspense fallback={'Loading...'}>
                <StandardLayout>
                    <ModelListView />
                </StandardLayout>
            </React.Suspense>
        </ProtectedRoute>,
    },
    {
        path: "/models/new",
        element: <ProtectedRoute>
            <React.Suspense fallback={'Loading...'}>
                <StandardLayout>
                    <NewModelView />
                </StandardLayout>
            </React.Suspense>
        </ProtectedRoute>,
    },
    {
        path: "/models/:modelId",
        element: <ProtectedRoute>
            <React.Suspense fallback={'Loading...'}>
                <StandardLayout>
                    <ModelView />
                </StandardLayout>
            </React.Suspense>
        </ProtectedRoute>,
    },
    {
        path: "/providers",
        element: <ProtectedRoute>
            <React.Suspense fallback={'Loading...'}>
                <StandardLayout>
                    <ProviderListView />
                </StandardLayout>
            </React.Suspense>
        </ProtectedRoute>,
    },
    {
        path: "/providers/new",
        element: <ProtectedRoute>
            <React.Suspense fallback={'Loading...'}>
                <StandardLayout>
                    <NewProviderView/>
                </StandardLayout>
            </React.Suspense>
        </ProtectedRoute>,
    },
    {
        path: "/providers/:providerId",
        element: <ProtectedRoute>
            <React.Suspense fallback={'Loading...'}>
                <StandardLayout>
                    <ProviderView/>
                </StandardLayout>
            </React.Suspense>
        </ProtectedRoute>,
    },
    {
        path: "/services/new",
        element: <ProtectedRoute>
            <React.Suspense fallback={'Loading...'}>
                <StandardLayout>
                    <NewServiceView/>
                </StandardLayout>
            </React.Suspense>
        </ProtectedRoute>,
    },
    {
        path: "/services/:serviceId",
        element: <ProtectedRoute>
            <React.Suspense fallback={'Loading...'}>
                <StandardLayout>
                    <ServiceView/>
                </StandardLayout>
            </React.Suspense>
        </ProtectedRoute>,
    },
    {
        path: "/services/:serviceId/submissions/:submissionId",
        element: <ProtectedRoute>
            <React.Suspense fallback={'Loading...'}>
                <StandardLayout>
                    <AITrainingView/>
                </StandardLayout>
            </React.Suspense>
        </ProtectedRoute>,
    },
    {
        path: "/auth/sign-in",
        element: <React.Suspense fallback={'Loading...'}>
            <LoginView/>
        </React.Suspense>,
    }
    , {
        path: "/auth/sign-up",
        element: <React.Suspense fallback={'Loading...'}>
            <SignUpView/>
        </React.Suspense>,
    },
];

const router = createBrowserRouter(raw_routes);


export default function routeProvider () {
    return <RouterProvider router={router}/>
}
