import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useQuery} from "@tanstack/react-query";
import {QueryResult} from "./types";
import {useRequireToken} from "./auth";
import {DefaultService, AIProvider, AIModel} from "./generated_client";

const BASE_QUERY_KEY = "providers";

function getQueryKey(providerId?:number, is_models:boolean=false){
    if(providerId) {
        return [BASE_QUERY_KEY, providerId, ...(is_models ? ["models"] : [])];
    }
    return [BASE_QUERY_KEY];
}

export function useProviderMutation(providerId:number|null) {
    useRequireToken();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (provider:AIProvider) => {
            let response: AIProvider;
            if(providerId){
                response = await DefaultService.updateAiProviderProvidersProviderIdPost(providerId, provider);
            } else {
                response = await DefaultService.createAiProviderProvidersPost(provider);
            }

            await queryClient.invalidateQueries({
                queryKey: getQueryKey(response.id)
            });
            queryClient.setQueryData(getQueryKey(response.id), response);
            return response;
        }
    });
}

export function useProviderQuery(providerId:number):QueryResult<AIProvider> {
    useRequireToken();

    return useQuery({
        queryKey: getQueryKey(providerId),
        queryFn: async () => {
            const response = await DefaultService.getAiProviderProvidersProviderIdGet(providerId);
            return response;
        },
    });
}

export function useProviderListQuery():QueryResult<AIProvider[]> {
    useRequireToken();

    return useQuery({
        queryKey: getQueryKey(),
        queryFn: async () => {
            const response = await DefaultService.listAiProvidersProvidersGet();
            return response;
        },
    });
}

export function useProviderModelListQuery(providerId:number):QueryResult<AIModel[]> {
    useRequireToken();

    return useQuery({
        queryKey: getQueryKey(providerId, true),
        queryFn: async () => {
            const response = await DefaultService.getAiProviderProvidersProviderIdModelsGet(providerId);
            return response;
        },
    });
}
