import {UseMutationResult} from "@tanstack/react-query/build/modern/index";
import {QueryResult} from "../services";
import {Responses} from "./requests";

type Result = UseMutationResult<any, any, any, any> | QueryResult<any>

export function hasError(...results:Result[]):boolean {
    for(const result of results) {
        if(result.isError) return true;
    }
    return false;
}

export function getError(...results:Result[]):string {
    let str:string = "";
    for(const result of results) {
        if(result.isError){
            str += Responses.error_detail(result)
        }
    }
    return str;
}

export function isPending(...results:Result[]):boolean {
    for(const result of results) {
        if(result.isPending) return true;
    }
    return false;
}
