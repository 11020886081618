import React, {FunctionComponent} from "react";
import { Button, Form, Input } from 'antd';
import {useLoginMutation} from "../services";
import {useNavigate} from "react-router-dom";
import Link from "antd/lib/typography/Link";


type FieldType = {
  username?: string;
  password?: string;
};

export const LoginView:FunctionComponent<{}> = (props: {}) => {
    // @ts-ignore
    const navigate = useNavigate();
    const loginMutation = useLoginMutation(() => {
        navigate("/");
    });

    const onFinish = (values: any) => {
        console.log('Success:', values);
        loginMutation.mutate(values);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    if(loginMutation.isPending) {
        return <div>Logging you in...</div>;
    }

    return <>
        <h1>Login</h1>
        { loginMutation.isError && (
            <div>Error: {loginMutation.error.message}</div>
        )}
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item<FieldType>
              label="Username"
              name="username"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item<FieldType>
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Link href="/auth/sign-up">Sign Up</Link>
              </Form.Item>
          </Form>
    </>
}