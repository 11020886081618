import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {QueryResult} from "./types";
import {useRequireToken} from "./auth";
import {DefaultService, AIService} from "./generated_client";



const BASE_QUERY_KEY = "ai_services";

export function getQueryKey(serviceId ?:number, is_docs:boolean=false) {
    if(serviceId){
        return [BASE_QUERY_KEY, serviceId, ...(is_docs ? ["docs"] : [])];
    }
    return [BASE_QUERY_KEY,];
}


export function useServiceListQuery():QueryResult<AIService[]> {
    useRequireToken();

    return useQuery({
        queryKey: getQueryKey(),
        queryFn: async () => {
            const response = await DefaultService.readAiServicesServicesGet();
            return response;
        }
    })
}

export function useServiceQuery(serviceId:number):QueryResult<AIService>{
    useRequireToken();

    return useQuery({
        queryKey: getQueryKey(serviceId),
        queryFn: async () => {
            const response = await DefaultService.readAiServiceServicesServiceIdGet(serviceId);
            return response;
        }
    })
}

export function useServiceMutation(serviceId:number){
    useRequireToken();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (service: AIService) => {
            const response = await DefaultService.updateAiServiceServicesServiceIdPost(serviceId, service);
            await queryClient.invalidateQueries({
                queryKey: getQueryKey(serviceId)
            });
            queryClient.setQueryData(getQueryKey(serviceId), response);
            return response;
        },
    });
}

export function useServiceRunMutation(serviceId:number){
    useRequireToken();

    return useMutation({
        mutationFn: async (service: Record<string, any>) => {
            const response = await DefaultService.runAiServiceServicesServiceIdRunPost(serviceId, service);
            return response;
        },
    });
}



export function useCreateNewServiceMutation() {
    useRequireToken();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (service:AIService) => {
            const response = await DefaultService.createAiServiceServicesPost(service, true);
            await queryClient.invalidateQueries({
                queryKey: getQueryKey(response.id)
            });
            queryClient.setQueryData(getQueryKey(response.id), response);
            return response;
        },
    });
}


export function useServiceDocsQuery( serviceId:number):QueryResult<Object>{
    useRequireToken();

    return useQuery({
        queryKey: getQueryKey(serviceId, true),
        queryFn: async () => {
            const response = await DefaultService.getServiceDocsServicesServiceIdDocsGet(serviceId);
            return response.data;
        }
    })
}


